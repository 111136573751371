<template>
  <div class="projects">
    <div class="container is-fluid">
      <page-header :entity="$EntitiesName.Project" :create="openCreateModal">
        <h1 class="title">Projets</h1>
      </page-header>

      <datatable :fetch="$Api.Project.fetchProjects" :entity="$EntitiesName.Project" ref="projectList" enableDownload>
        <div slot="otps" slot-scope="{ item }" title="Codes SAP">
          <span v-for="(otp) in item.otps" :key="'i_' + otp.eotp" class="tag is-info is-light">{{ otp.otp }}</span>
        </div>
        <div slot="name" slot-scope="{ item }" title="Nom" :sortable="true">{{ item.name }}</div>
        <div slot="direction" slot-scope="{ item: { region } }" title="Direction" :sortable="true" sortBy="region.direction.name">{{ region && region.direction.name }}</div>
        <div slot="region" slot-scope="{ item: { region } }" title="Région" :sortable="true" sortBy="region.name">{{ region && region.name }}</div>
        <div slot="startDate" slot-scope="{ item }" title="Date de début" :sortable="true" type="date">{{ item.startDate | date }}</div>
        <div slot="endDate" slot-scope="{ item }" title="Date de fin" :sortable="true" type="date">{{ item.endDate | date }}</div>
      </datatable>
    </div>

    <modal ref="createProjectModal" @create="createProject" title="Création d'un projet">
      <div class="columns">
        <div class="column is-half">
        <ValidationObserver ref="form">
          <!-- <many2one-field
            label="Code SAP"
            v-model="project.otp"
            :fetch="$Api.Otp.fetchAvailableOtps"
            reference="otp"
            entity="otp"
            :columns="otpColumns"
            :hasFilter="true"
            :inline="false"
            :edit="true"
            required
          ></many2one-field> -->
          <text-field label="Nom" v-model="project.name" :inline="false" :edit="true" required></text-field>
          <many2one-field
            label="Région"
            v-model="project.region"
            :fetch="$Api.Region.fetchRegions"
            reference="name"
            entity="region"
            :columns="regionColumns"
            @click="(item) => $router.push('/regions/' + item.id)"
            :hasFilter="true"
            :inline="false"
            :edit="true"
            required
          ></many2one-field>
          <many2one-field
            label="Responsable de projet "
            v-model="project.owner"
            :fetch="$Api.User.fetchUsers"
            reference="name"
            entity="user"
            :columns="userColumns"
            :hasFilter="true"
            :inline="false"
            :edit="true"
          ></many2one-field>
          <date-field
            label="Date de début de projet"
            v-model="project.startDate"
            :edit="true"
            :inline="false"
          ></date-field>
          <date-field
            label="Date de fin de projet"
            v-model="project.endDate"
            :edit="true"
            :inline="false"
          ></date-field>
          <selector-field
            :options="$Api.Project.fetchActivities"
            label="Calcul d'activité"
            v-model="project.activity"
            :inline="false"
            :edit="true"
          ></selector-field>
        </ValidationObserver>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import axios from 'axios';
import Datatable from '../../components/elements/Datatable';

export default {
  name: 'projects',
  components: {
    Datatable,
  },
  data() {
    return {
      project: {},
      regionColumns: {
        'direction.name': 'Direction',
        name: 'Région',
      },
      otpColumns: {
        otp: 'Code SAP',
      },
      userColumns: {
        name: 'Nom',
        mail: 'E-Mail',
        'role.name': 'Role',
        service: 'Service',
      },
    };
  },
  methods: {
    openCreateModal() {
      this.$refs.createProjectModal.open();
    },
    createProject() {
      this.$refs.form.validate().then(success => {
        if (!success) {
          return;
        }
        const project = {
          ...this.project,
        };

        axios.post('/projects', project).then((response) => {
          if (response.data.success) {
            this.project = {};
            this.$refs.projectList.refresh();
            this.$refs.createProjectModal.close();
            this.$router.push({
              name: 'Project',
              params: { id: response.data.id },
            });
          }
        });
      })
    },
  },
};
</script>

<style scoped>
</style>
